/* eslint-disable simple-import-sort/imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { DEFAULT_COMPANY_PROFILE, routes, USER_DATA_LOCAL_STORAGE_KEY } from 'constant';
import { Companies } from 'fetures/MyCompany/Components';
import { TopNavigationBar } from 'components';
import { deleteCompanyProfile, getCompanyProfile } from 'api/companyProfile';
import { fetchAllSubscriptions } from 'api/payment';
import { catchBlock } from 'utils';

import './style.css';
import { fetchCompanyProfile, getDefaultCompanyProfile, setDefaultCompanyProfile } from 'reducers/CompanyProfileSlice';

const MyCompany = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));
  const defaultCompanyProfile = useSelector(getDefaultCompanyProfile);
  const [slots, setSlots] = useState([]);
  const [subAccounts, setSubAccounts] = useState([]);
  const [companyProfileData, setCompanyProfileData] = useState([]);

  const { mutate: deleteCompanyProfileApi, isLoading: isLoadingDeleteCompanyProfile } = useMutation(
    deleteCompanyProfile,
    {
      onSuccess: (data, variables) => {
        const companyProfiles = companyProfileData.filter((c) => c._id !== variables) || [];
        setCompanyProfileData(companyProfiles);
        dispatch(fetchCompanyProfile());

        // const defaultCompanyProfile = localStorage.getItem(DEFAULT_COMPANY_PROFILE)
        //   ? JSON.parse(localStorage.getItem(DEFAULT_COMPANY_PROFILE))
        //   : null;

        if (defaultCompanyProfile && defaultCompanyProfile.value === variables) {
          dispatch(
            setDefaultCompanyProfile({ value: companyProfiles?.[0]._id, label: companyProfiles?.[0].name || '' }),
          );
        }

        Swal.fire('Deleted!', 'Your company profile has been deleted.', 'success');
      },
      onError: (error) => {
        catchBlock(error);
      },
    },
  );

  const { mutate: mutateAllSubscriptions, isFetching: isLoadingSubscriptions } = useMutation(fetchAllSubscriptions, {
    onSuccess: async (data) => {
      setSlots(data?.qty && data?.qty > 0 ? Array.from(Array(data.qty - 1), (_, i) => i + 1) : []);
      setSubAccounts(data?.subAccounts || []);
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const { mutate: getCompanyProfileApi, isFetching: isLoadingCompanyProfile } = useQuery({
    queryKey: ['get-company-profile-list'],
    queryFn: getCompanyProfile,
    refetchOnMount: 'always',
    onSuccess: async (response) => {
      setCompanyProfileData(response?.data || []);
    },
    onError: (err) => {
      catchBlock(err);
    },
  });

  const refetch = () => {
    setSlots([]);
    mutateAllSubscriptions();
  };

  const updateCompanyProfileData = (id, data) => {
    setCompanyProfileData((prevData) => prevData.map((item) => (item._id === id ? { ...item, ...data } : item)));
  };

  useEffect(() => {
    mutateAllSubscriptions();
  }, []);

  useEffect(() => {
    if (getCompanyProfileApi) getCompanyProfileApi();
  }, [getCompanyProfileApi]);

  return (
    <>
      {isLoadingCompanyProfile && (
        <div className="myaccount_page-spinner-wrapper">
          <Spinner animation="border" className="myaccount_page-spiner" />
        </div>
      )}

      <div className="myaccount_page-wrapper">
        <TopNavigationBar isShowCompanyDropdown={false} logoLink={routes.home} />

        <div className="myaccount_page-title-wrapper">
          <h3 className="myaccount_page-title">Companies</h3>
        </div>

        <div className="myaccount_page-container m-auto">
          <Companies
            companyProfileData={companyProfileData}
            deleteCompanyProfileApi={deleteCompanyProfileApi}
            isLoadingDeleteCompanyProfile={isLoadingDeleteCompanyProfile}
            updateCompanyProfileData={updateCompanyProfileData}
          />
        </div>
      </div>
    </>
  );
};

export default MyCompany;
