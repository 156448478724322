/* eslint-disable import/order */
import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';
import { DEFAULT_COMPANY_PROFILE } from 'constant';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { getDefaultCompanyProfile } from 'reducers/CompanyProfileSlice';
import { InputBox } from 'components';
import animationSerchIcon from 'assets/search.json';

import './style.css';

const TitleandSerchbar = ({ counter, filterFormRef, refetchWorkspace, others = false }) => {
  const companyProfile = useSelector(getDefaultCompanyProfile);

  const [deleteWorkcardId, setDeleteWorkcardId] = useState(-1);

  return (
    <div>
      <div className="mianDiv">
        {others !== true ? (
          <div className="searchBar">
            <h3 className="mb-0 h3Class mt-3">
              {companyProfile && companyProfile?.label ? companyProfile?.label : 'My'} Workspaces
              <span className="span">{counter}</span>
            </h3>

            <div className="formik-form">
              <Formik initialValues={{ marketing: '' }} innerRef={filterFormRef} onSubmit={refetchWorkspace}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form">
                      <div>
                        <Field
                          placeholder="Search workspaces"
                          name="marketing"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.marketing}
                          component={InputBox}
                          customClass="input-style"
                          type="text"
                        />
                        {errors.marketing && touched.marketing ? (
                          <div className="lightRed d-block  justify-content-center mx-auto text-start">
                            {errors.marketing}
                          </div>
                        ) : null}
                      </div>
                      <button
                        type="submit"
                        onClick={() => {
                          setDeleteWorkcardId(1);

                          setTimeout(() => {
                            setDeleteWorkcardId(-1);
                          }, 950);

                          return;
                        }}
                        style={{ background: 'none', border: 'none' }}
                      >
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: false,
                            animationData: animationSerchIcon,
                          }}
                          height={30}
                          width={30}
                          style={{ margin: ' 0px ' }}
                          isStopped={deleteWorkcardId !== 1}
                        />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

TitleandSerchbar.defaultProps = {
  counter: PropTypes.number,
  filterFormRef: PropTypes.object,
  refetchWorkspace: PropTypes.func,
  others: PropTypes.bool,
};

TitleandSerchbar.propTypes = {
  counter: PropTypes.number || null,
  filterFormRef: PropTypes.object || null,
  refetchWorkspace: PropTypes.func || null,
  others: PropTypes.bool,
};

export default TitleandSerchbar;
