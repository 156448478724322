/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCompanyProfile } from 'api/companyProfile';

const initialState = {
  companyProfiles: [],
  defaultCompanyProfile: null,
  loading: false,
  isCompanyProfileLoading: false,
  error: null,
  totalCompanyProfile: 0,
};

export const fetchCompanyProfile = createAsyncThunk('companyProfile', async (req) => {
  const response = await getCompanyProfile();
  return response;
});

const companyProfileSlice = createSlice({
  name: 'companyProfiles',
  initialState,
  reducers: {
    setDefaultCompanyProfile: (state, action) => {
      state.defaultCompanyProfile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyProfile.pending, (state, action) => {
        state.loading = true;
        state.isCompanyProfileLoading = true;
      })
      .addCase(fetchCompanyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.isCompanyProfileLoading = false;
        // state.teams = action?.payload?.data || [];

        if (action?.payload?.data) {
          state.companyProfiles = action?.payload?.data || [];
          // const defaultCompanyProfile = state.companyProfiles.find((company) => company.isDefault === true);
          // state.defaultCompanyProfile = defaultCompanyProfile;
          state.totalCompanyProfile = action?.payload?.data?.length || 0;
        } else {
          state.companyProfiles = [];
          state.totalCompanyProfile = 0;
        }
      })
      .addCase(fetchCompanyProfile.rejected, (state, action) => {
        state.loading = false;
        state.isCompanyProfileLoading = false;
        state.error = action.payload;
      });
  },
});
export const getCompanyProfiles = (state) => state.companyProfiles.companyProfiles;
export const getDefaultCompanyProfile = (state) => state.companyProfiles.defaultCompanyProfile;
export const { setDefaultCompanyProfile } = companyProfileSlice.actions;
export default companyProfileSlice.reducer;
